var translations = {
    en: {
        '404page.message': 'The page was not found.',
        'homepage.welcome': 'Welcome',
        'homepage.meetingDetails': 'Meeting details',
        'homepage.showroomButtonText': 'Enter showroom',
        'homepage.generalShowrooms': 'Basic showrooms',
        'homepage.otherMeetings': 'Other meetings',
        'leaveMeeting.warning': 'Warning',
        'leaveMeeting.heading': 'You are changing meeting',
        'leaveMeeting.text1': 'Meaning you favorites list will be saved here in this meeting but will not be available in the meeting you are going to.',
        'leaveMeeting.text2': 'That meeting have a seperate favorites list.',
        'favorites.heading': 'Favorites',
        'favorites.submitFeedback': 'Your favorites have been sent to the B2B shop',
        'favorites.emptyList': 'You have not added any favorites',
        'favorites.submitOrder': 'Send favorites to B2B shop',
        'favorites.marketingMaterialBtn': 'Marketing Material',
        'productPopup.priceMsrp': 'RRP:',
        'productPopup.yourPrice': 'Your price:',
        'productPopup.focus': 'Focus',
        'productPopup.details': 'Details',
        'productPopup.features': 'Features',
        'productPopup.close': 'Close',
        'productPopup.info': 'Info',
        'productPopup.detailHeadingLine1': 'It is all in',
        'productPopup.detailHeadingLine2': 'The detail',
        'productPopup.featuresHeadingLine1': 'More than',
        'productPopup.featuresHeadingLine2': 'Features',
        'showroomMenu.menu': 'Menu',
        'showroomMenu.close': 'Close',
        'showroomMenu.navigation': 'Navigation',
        'showroomMenu.chooseShowroom': 'Choose showroom',
        'showroomMenu.b2bPortal': 'Portal',
        'showroomMenu.marketingMaterial': 'Marketing material',
        'showroomMenu.capPlan': 'Cap plan',
        'showroomMenu.campaignMaterial': 'Campaign Material',
        'showroomMenu.brandVideo': 'Brand video',
        'showroomMenu.favorites': 'Favorites'
    },
    /*
    da: {
        '404page.message': 'Siden blev ikke fundet.',
        'homepage.welcome': 'Velkommen',
        'homepage.meetingDetails': 'Møde info',
        'homepage.showroomButtonText': 'Besøg showroom',
        'homepage.generalShowrooms': 'Basic showrooms',
        'homepage.otherMeetings': 'Andre møder',
        'leaveMeeting.warning': 'Advarsel',
        'leaveMeeting.heading': 'Du er ved at skifte møde',    
        'leaveMeeting.text1': 'Det betyder din favorites liste vil blive gemt her i dette møde, men ikke vil være tilgængelig inde i det møde du er på vej til.',
        'leaveMeeting.text2': 'Det møde har sin egen favorites liste.',
        'favorites.heading': 'Favoritter',
        'favorites.submitFeedback': 'Dine favoritter er sendt videre til BTB shoppen',
        'favorites.emptyList': 'Du har endnu ikke tilføjet favoritter',
        'favorites.submitOrder': 'Send favoritter til BTB shop',
        'favorites.marketingMaterialBtn': 'Marketing Material',
        'productPopup.priceMsrp': 'RRP:',
        'productPopup.yourPrice': 'Din pris:',
        'productPopup.focus': 'Fokus',
        'productPopup.details': 'Detaljer',
        'productPopup.features': 'Features',
        'productPopup.close': 'Luk',
        'productPopup.info': 'Info',
        'productPopup.detailHeadingLine1': 'It is all in',
        'productPopup.detailHeadingLine2': 'The detail',
        'productPopup.featuresHeadingLine1': 'More than',
        'productPopup.featuresHeadingLine2': 'Features',
        'showroomMenu.menu': 'Menu',
        'showroomMenu.close': 'Close',
        'showroomMenu.navigation': 'Navigation',
        'showroomMenu.chooseShowroom': 'Choose showroom',
        'showroomMenu.b2bPortal': 'B2B Portal',
        'showroomMenu.marketingMaterial': 'Marketing material',
        'showroomMenu.capPlan': 'Cap plan',
        'showroomMenu.campaignMaterial': 'Campaign Material',
        'showroomMenu.brandVideo': 'Brand video',
        'showroomMenu.favorites': 'Favorites'
    }
    */
};

var contentLocalized = {
    en: {
        'showroomMenu.b2bPortalLink': 'https://salesagent.outfitinternational.com/home/',
        'showroomMenu.capPlanLinkHarkila': 'https://seelandinternational.sharepoint.com/:x:/r/sites/IntSales/_layouts/15/Doc.aspx?sourcedoc=%7BE990C5D8-1AE6-46C8-BA62-185F1482DA5F%7D&file=H%C3%A4rkila_CAP_2022%2C%20final.xlsx&action=default&mobileredirect=true&cid=15251251-b390-4c66-9f73-6722e9784847',
        'showroomMenu.capPlanLinkSeeland': 'https://seelandinternational.sharepoint.com/:x:/r/sites/IntSales/_layouts/15/Doc.aspx?sourcedoc=%7BE7D94CAD-3C44-4B5E-B2C8-C9839FB55BC7%7D&file=Seeland_2022_CAP%2C%20final.xlsx&action=default&mobileredirect=true&cid=45fefe47-55a0-476d-ab80-f5c392192606',
        'showroomMenu.campaignMaterialLinkHarkila': 'https://b2b.outfitinternational.com/~/b2b/links/presskit/',
        'showroomMenu.campaignMaterialLinkSeeland': 'https://b2b.outfitinternational.com/~/b2b/links/presskit/',
        'showroomMenu.brandVideoSrcHarkila': '/videos/brand-videos/harkila.m4v?v=1',
        'showroomMenu.brandVideoSrcSeeland': '/videos/brand-videos/seeland-2.m4v?v=1'
    },
    /*
    da: {
        'showroomMenu.b2bPortalLink': 'https://b2b.outfitinternational.com/',
        'showroomMenu.capPlanLink': 'https://seelandinternational.sharepoint.com/sites/IntSales/',
        'showroomMenu.campaignMaterialLinkHarkila': 'https://b2b.outfitinternational.com/~/b2b/links/presskit/',
        'showroomMenu.campaignMaterialLinkSeeland': 'https://b2b.outfitinternational.com/~/b2b/links/presskit/',
        'showroomMenu.brandVideoSrcHarkila': '/videos/brand-videos/harkila.m4v?v=1',
        'showroomMenu.brandVideoSrcSeeland': '/videos/brand-videos/seeland-2.m4v?v=1'
    },
    */
};

var currentLangauge = 'en';

export function setLanguage(stringId)
{
    currentLangauge = stringId;
}

export function getLanguage()
{
    return currentLangauge;
}

export function language(stringId)
{
    // NOTE: We only show system texts in english
    return translations.en[stringId];
}

export function content(stringId)
{
    // NOTE: We do not currently localize content
    return contentLocalized.en[stringId];
}

export function translateObject(obj)
{
    if (typeof obj === 'string')
    {
        return obj
    }
    return obj[currentLangauge]
}