<template>
    <div ref="scrollbar" class="scrollbar" :class="{'scrollbar--left-side': leftSide}">
        <slot></slot>
    </div>
</template>

<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

export default {
    props:
    {
        leftSide: Boolean
    },
    mounted()
    {
        this.simpleBar = new SimpleBar(this.$refs.scrollbar, { autoHide: false });
    },
    unmounted()
    {
        if (this.simpleBar)
        {
            this.simpleBar.unMount();
        }
    }
}
</script>