<template>
	<div class="product-slideshow" :class="'product-slideshow--'+brand">

		<div class="product-slideshow__frame">

			<div class="product-slideshow__slides" :style="'transform: translateX(-'+ currentSlideIndex*100 +'%)'">

				<div v-if="productURL360LowRes || productURL360HighRes" class="product-slideshow__slide product-slideshow__slide--360-iframe">
					<div class="viewer-360 product-slideshow__viewer-360" :class="'viewer-360--'+brand">
						<iframe :src="(productURL360LowRes || productURL360HighRes)"/>
						<div class="viewer-360__icon"></div>
						<div class="viewer-360__fullscreen-btn" @click="$emit('open-fullscreen360', (productURL360HighRes || productURL360LowRes))"></div>
					</div>
				</div>

				<template v-for="(image, i) in productImages" v-bind:key="i">
					<div v-if="image" class="product-slideshow__slide" :class="'product-slideshow__slide--'+image.type">
						<img :src="image.url"/>
					</div>
				</template>
			</div>
		</div>

		<div v-if="currentSlideIndex > 0" class="product-slideshow__arrow product-slideshow__arrow--left" @click="prevSlide"></div>
		<div v-if="currentSlideIndex +1 < productImages.length" class="product-slideshow__arrow product-slideshow__arrow--right" @click="nextSlide"></div>
		<div class="product-slideshow__dot-nav">
			<div class="product-slideshow__dot" 
			:class="{'product-slideshow__dot--is-current': (i === currentSlideIndex)}"
			v-for="(image, i) in productImages" 
			v-bind:key="i" 
			@click="goToSlide(i)"></div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'CaseSlideshow',
	props: {
		productImages: Array,
		brand: String,
		productURL360LowRes: String,
		productURL360HighRes: String
	},
	data()
	{
		return {
			currentSlideIndex: 0
		}
	},
	methods:
	{
		goToSlide(index)
		{
			this.currentSlideIndex = index;
		},
		prevSlide()
		{
			if (this.currentSlideIndex > 0)
			{
				this.currentSlideIndex--;
			}
		},
		nextSlide()
		{
			if (this.currentSlideIndex+1 < this.productImages.length)
			{
				this.currentSlideIndex++;
			}
		}
	}
}
</script>