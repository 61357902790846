import store from '@/dataStore';

export function linkToShowRoom(showroomId, route)
{
    const isInMeetingContext = (!store.isMeetingLoaded(-1));

    if (isInMeetingContext)
    {
        return {path: 'showroom/'+showroomId+'/', query: route.query};
    }
    else
    {
        return {path: '/showroom/'+showroomId+'/', query: route.query};
    }
}

export function linkToMeeting(meetingId, route)
{
    return {path: '/meeting/'+meetingId+'/', query: route.query};
}