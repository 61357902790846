<template>

    <div>

        <div class="product-popup" :class="['product-popup--'+brandNameForCSSClass]" @scroll="handleScroll" ref="scrollWrapper" :key="product.productID" @click.self="$emit('close-product')">
            <div class="product-popup__overlay" @click="$emit('close-product')"></div>
            <div class="product-popup__inner">
                
                <div class="product-popup__header" :class="{'product-popup__header--undocked': (this.scrollOffset > 0)}">
                    <div class="product-popup__navigation">
                        <div class="product-popup__header-link" @click="scrollTo('sectionFocus')">{{language('productPopup.focus')}}</div>
                        <div v-if="product.productDetails && product.productDetails.length" class="product-popup__header-link" @click="scrollTo('sectionDetails')">{{language('productPopup.details')}}</div>
                        <div v-if="product.technology && product.technology.length" class="product-popup__header-link" @click="scrollTo('sectionFeatures')">{{language('productPopup.features')}}</div>
                    </div>

                    <div class="product-popup__number">{{productNumber}}</div>
                    
                    <template v-if="!disableFavorites">
                        <div
                            v-if="!isInFavorites"
                            class="product-popup__favorites-btn"
                            :class="{'product-popup__favorites-btn--is-updating': isUpdatingFavorites}"
                            @click="$emit('add-favorite', product)">
                        </div>

                        <div
                            v-if="isInFavorites"
                            class="product-popup__favorites-btn product-popup__favorites-btn--selected"
                            :class="{'product-popup__favorites-btn--is-updating': isUpdatingFavorites}"
                            @click="$emit('delete-favorite', product.productID)">
                        </div>
                    </template>

                </div>

                <div class="product-popup__section-product-images" ref="sectionFocus">

                    <div class="product-popup__main-info">

                        <div class="product-popup__name">
                            <div class="product-popup__family">{{product.productFamilyName}}</div>
                            <div class="product-popup__name-main">{{product.productName}}</div>
                        </div>

                        <div class="product-popup__price" v-if="!isDemoMode">
                            <div class="product-popup__price-left-col">
                                <div v-if="priceProductMsrpInCurrentCurrency" class="product-popup__price-label">
                                    <span>{{language('productPopup.priceMsrp')}}</span>
                                </div>
                                <div v-if="product.productCustomerPrice" class="product-popup__price-label">
                                    <span>{{language('productPopup.yourPrice')}}</span>
                                </div>
                            </div>
                            <div class="product-popup__price-right-col">
                                <div v-if="priceProductMsrpInCurrentCurrency" class="product-popup__price-value">
                                    <span>{{formatPrice(priceProductMsrpInCurrentCurrency)}} <span class="product-popup__currency-symbol">{{currencySymbol}}</span></span>
                                </div>
                                <div v-if="product.productCustomerPrice" class="product-popup__price-value product-popup__price-value--customer">
                                    <span>{{formatPrice(product.productCustomerPrice)}} <span class="product-popup__currency-symbol">{{currencySymbol}}</span></span>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="product-popup__product-images-slider">
                        <ProductSlideshow @openFullscreen360="openFullscreen360" :brand="brandNameForCSSClass" :productURL360LowRes="product.productURL360LowRes" :productURL360HighRes="product.productURL360HighRes" :productImages="productImages"></ProductSlideshow>
                    </div>

                    <div class="product-popup__environment-image-top"><img v-if="topEnvironmentImage" :src="topEnvironmentImage.url"></div>

                    <div v-if="product.productShortWebText || product.productLongWebText" class="product-popup__info" :class="{'product-popup__info--is-open': moreInfoIsOpen}">
                        <div class="product-popup__info-toggle-btn" @click="moreInfoIsOpen = !moreInfoIsOpen">
                            <span v-if="moreInfoIsOpen">{{language('productPopup.close')}}</span>
                            <span v-else>{{language('productPopup.info')}}</span>
                        </div>
                        <div v-if="product.productLongWebText" v-html="product.productLongWebText"></div>
                        <div v-else-if="product.productShortWebText" v-html="product.productShortWebText"></div>
                    </div>
                
                </div>

                <div v-if="detailImages.length" class="product-popup__section-detail-images">
                    <template v-for="(image, i) in detailImages" v-bind:key="image">
                        <div v-if="i < 2" class="product-popup__detail-image"><img :src="image.url"></div>
                    </template>
                </div>

                <div v-if="product.productDetails && product.productDetails.length" class="product-popup__section-detail-text" ref="sectionDetails">
                    <div class="product-popup__heading">
                        <div class="product-popup__heading-top">{{language('productPopup.detailHeadingLine1')}}</div>
                        <div class="product-popup__heading-bottom">{{language('productPopup.detailHeadingLine2')}}</div>
                    </div>
                    <template v-for="(detail, i) in product.productDetails" v-bind:key="i">
                        <div>{{detail}}</div>
                    </template>
                    <div class="product-popup__product-image-detail-crop">
                        <img v-if="productImages.length" :src="productImages[0].urlLarge"/>
                    </div>
                </div>

                <template v-if="product.technology && product.technology.length">
                    <div class="product-popup__section-technology-1">
                        <div class="product-popup__environment-image-bottom"><img v-if="bottomEnvironmentImage" :src="bottomEnvironmentImage.url"></div>
                    </div>
                    <div class="product-popup__section-technology-2" ref="sectionFeatures">
                        <div class="product-popup__features-list">
                            <div class="product-popup__features-heading">
                                <div class="product-popup__heading">
                                    <div class="product-popup__heading-top">{{language('productPopup.featuresHeadingLine1')}}</div>
                                    <div class="product-popup__heading-bottom">{{language('productPopup.featuresHeadingLine2')}}</div>
                                </div>
                            </div>
                            <div>
                                <template v-for="(technology, i) in product.technology" v-bind:key="i">
                                    <div class="product-popup__feature">
                                        <div class="product-popup__feature-icon" v-if="technology.technologyUrl">
                                            <img :src="technology.technologyUrl"/>
                                        </div>
                                        {{technology.technologyName}}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- TODO: Add transition when approved -->
        <transition name="circlewipe">
            <div v-if="viewer360Url" class="viewer-360-popup" :class="['viewer-360-popup--'+brandNameForCSSClass]">

                <div class="viewer-360-popup__transition-mask">

                    <div class="viewer-360-popup__overlay" @click="viewer360Url = ''"></div>
                    <div class="viewer-360-popup__inner">

                        <div class="viewer-360-popup__header">{{product.productName}}</div>

                        <div class="viewer-360-popup__close-btn" @click="viewer360Url = ''"></div>

                        <div class="viewer-360-popup__viewer viewer-360 viewer-360--is-fullscreen" :class="'viewer-360--'+brandNameForCSSClass">
                            <iframe :src="viewer360Url"/>
                            <div class="viewer-360__icon"></div>
                        </div>
                        
                    </div>

                </div>

            </div> 
        </transition>
        
        <div class="product-navigation" :class="'product-navigation--'+brandNameForCSSClass">

            <div class="product-navigation__items-left">
                <a v-for="(i) in prevProductIndexes" class="product-navigation__item product-navigation__item--dot" @click="goToSiblingProduct(i)" v-bind:key="i"></a>
                <a class="product-navigation__item product-navigation__item--arrow-left" :class="{'product-navigation__item--unavailable': currentProductSlideIndex === 0}" @click="openPrevProduct"></a>
            </div>

            <div class="product-navigation__items-right">
                <a class="product-navigation__item product-navigation__item--arrow-right" :class="{'product-navigation__item--unavailable': currentProductSlideIndex+1 === siblingProducts.length}" @click="openNextProduct"></a>
                <a v-for="(i) in nextProductIndexes" class="product-navigation__item product-navigation__item--dot" @click="goToSiblingProduct(i)" v-bind:key="i"></a>
            </div>

        </div>

    </div>

</template>

<script>
import pad from '@/helpers/pad';
import { language } from '@/helpers/language'
import { sortBasedOnFilenameSuffix } from '@/helpers/image-helper';
import ProductSlideshow from '@/components/ProductSlideshow';

export default {
    name: 'ProductPopup',
    props: {
        product: Object,
        brandName: String,
        siblingProducts: Array,
        favorites: Array,
        isUpdatingFavorites: Boolean,
        disableFavorites: Boolean,
        currency: String
    },
    components: {
        ProductSlideshow
    },
    data()
    {
        return {
            language: language,
            scrollOffset: 0,
            moreInfoIsOpen: false,
            viewer360Url: '',
        };
    },
    computed: {
        isDemoMode()
        {
            return (this.$route.query && this.$route.query.demomode);
        },
        priceProductMsrpInCurrentCurrency()
        {
            const msrp = this.product.productMsrp
            if (msrp !== undefined && typeof msrp === 'number')
            {
                return msrp
            }
            return ''
        },
        currencySymbol()
        {
            var symbolString = this.currency;

            switch(this.currency)
            {
                case 'dkk':
                    symbolString = 'DKK';
                    break;
                case 'eur':
                    symbolString = '€';
                    break;
                case 'gbp':
                    symbolString = '£';
                    break;
                case 'usd':
                    symbolString = '$';
                    break
                case 'cad':
                    symbolString = 'CAD';
                    break
                default:
                    break;
            }

            return symbolString;
        },
        isInFavorites()
        {
            var result = false;

            this.favorites.forEach((product)=>{
                if (product.productID === this.product.productID)
                {
                    result = true;
                }
            });

            return result;
        },
        currentProductSlideIndex()
        {
            var index = 0;

            this.siblingProducts.forEach((product, i)=>{
                if (product === this.product)
                {
                    index = i;
                }
            });

            return index;
        },
        prevProductIndexes()
        {
            var result = [];

            for (var i = 0; i < this.currentProductSlideIndex; i++)
            {
                result.push(i);
            }

            return result;
        },
        nextProductIndexes()
        {
            var result = [];

            for (var i = this.currentProductSlideIndex+1; i < this.siblingProducts.length; i++)
            {
                result.push(i);
            }

            return result;
        },
        productNumber()
        {
            return pad(this.currentProductSlideIndex + 1, 2);
        },
        productImages()
        {
            var images = [];

            if (this.product.resources)
            {
                this.product.resources.forEach((resource) => {
                    if (resource.resourceCumulusImageView === 'Base')
                    {
                        images.push({
                            type: 'base',
                            resourceFilename: resource.resourceFilename, 
                            url: this.getImageUrlFromResource(resource),
                            urlLarge: this.getImageUrlFromResource(resource), // 'large'
                        });
                    }
                });

                images = sortBasedOnFilenameSuffix([...images])

                this.product.resources.forEach((resource) => {
                    if (resource.resourceCumulusImageView === 'DetailPhotos')
                    {
                        images.push({
                            type: 'detail',
                            url: this.getImageUrlFromResource(resource),
                            urlLarge: this.getImageUrlFromResource(resource), // 'large'
                        });
                    }
                });
            }
            return images;
        },
        detailImages()
        {
            var images = [];

            if (this.product.resources)
            {
                // NOTE: First pass, get 'Macro' (we prioritize those) 
                this.product.resources.forEach((resource)=>{
                    if (resource.resourceCumulusImageView === 'Macro')
                    {
                        images.push({
                            url: this.getImageUrlFromResource(resource)
                        });
                    }
                });
            
                // NOTE: Second pass, get DetailPhotos images
                this.product.resources.forEach((resource)=>{
                    if (resource.resourceCumulusImageView === 'DetailPhotos')
                    {
                        images.push({
                            url: this.getImageUrlFromResource(resource)
                        });
                    }
                });
            }

            return images;
        },
        environmentImages()
        {
            var images = [];

            if (this.product.resources)
            {
                this.product.resources.forEach((resource)=>{
                    if (resource.resourceCumulusImageView === 'Model')
                    {
                        images.push({
                            url: this.getImageUrlFromResource(resource)
                        });
                    }
                });
            }

            if (this.product.section)
            {
                this.product.section.resources.forEach((resource)=>{
                    if (resource.resourceCumulusImageView === 'Model')
                    {
                        images.push({
                            url: this.getImageUrlFromResource(resource)
                        });
                    }
                });
            }

            return images;

        },
        topEnvironmentImage()
        {
            if (this.environmentImages.length)
            {
                return this.environmentImages[0];
            }

            return null;
        },
        bottomEnvironmentImage()
        {
            if (this.environmentImages.length > 1)
            {
                return this.environmentImages[1];
            }
            else
            {
                return this.topEnvironmentImage;
            }
        },
        brandNameForCSSClass()
        {
            if (this.brandName === 'Härkila')
            {
                return 'harkila';
            }
            else if (this.brandName === 'Seeland')
            {
                return 'seeland'
            }
            
            return '';
        }
    },
    created()
    {
        // NOTE: Keyboard events
        this.handleKeyInput = (event)=>
        {
            if (!this.selectedProduct)
            {
                if (event.keyCode == '37')
                {
                    this.openPrevProduct();
                }
                else if (event.keyCode == '39')
                {
                    this.openNextProduct();
                }
            }
        };
        document.addEventListener('keydown', this.handleKeyInput);
    },
    unmounted()
    {
        document.removeEventListener('keydown', this.handleKeyInput);
    },
    methods:
    {
        openFullscreen360(iframeUrl)
        {
            this.viewer360Url = iframeUrl;
        },
        formatPrice(value)
        {
            return parseFloat(value).toFixed(2);
        },
        goToSiblingProduct(siblingIndex)
        {
            var productId = this.siblingProducts[siblingIndex].productID;
            this.$emit('open-product', productId);
        },
        handleScroll()
        {
            this.scrollOffset = this.$refs.scrollWrapper.scrollTop;
        },
        scrollTo(scrollSectionRefName)
        {            
            var scrollSectionElement = this.$refs[scrollSectionRefName];

            if (scrollSectionElement)
            {    
                this.$refs.scrollWrapper.scrollTo({
                    top: scrollSectionElement.offsetTop - 90,
                    left: 0,
                    behavior: 'smooth'
                });
            }


        },
        getImageUrlFromResource(resource)
        {
            return resource.resourceUrl            
        },
        openNextProduct()
        {
            var nextProduct = null;

            var currentProductFound = false;
            this.siblingProducts.forEach((product)=>{
                if (product === this.product)
                {
                    currentProductFound = true;
                }
                else if (currentProductFound && !nextProduct)
                {
                    nextProduct = product;
                }
            });

            if (nextProduct)
            {
                this.$emit('open-product', nextProduct.productID);
            }
        },
        openPrevProduct()
        {
            var prevProduct = null;

            var siblingProductsReversed = this.siblingProducts.slice().reverse();

            var currentProductFound = false;
            siblingProductsReversed.forEach((product)=>{
                if (product === this.product)
                {
                    currentProductFound = true;
                }
                else if (currentProductFound && !prevProduct)
                {
                    prevProduct = product;
                }
            });

            if (prevProduct)
            {
                this.$emit('open-product', prevProduct.productID);
            }
        }
    }
}
</script>
