<template>
    <div class="loading-screen">
        <div class="loading-screen__spinner"></div>
    </div>
</template>

<script>
export default {
    name: 'LoadingScreen'
}
</script>
