<template>
  <div class="page-not-found">
    <p>{{language('404page.message')}}</p>
  </div>
</template>

<script>
import {language} from '@/helpers/language'

export default {
  name: 'Page404',
  components: {},
  data() {
    return {
      language: language
    }
  }
}
</script>
