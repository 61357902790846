<template>

  <AspectRatio>

    <transition name="fade" mode="out-in">
      <LoadingScreen v-if="isLoading"></LoadingScreen>
    </transition>

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

  </AspectRatio>

</template>

<script>
import './scss/main.scss';
import store from './dataStore';
import AspectRatio from './components/AspectRatio';
import LoadingScreen from './components/LoadingScreen';

export default {
  name: 'App',
  components: {
    AspectRatio: AspectRatio,
    LoadingScreen: LoadingScreen
  },
  data() {
    return {
      isLoading: false
    }
  },
  created()
  {
    this.unsubscribeFromStore = store.subscribe((storeState)=>{
        this.isLoading = storeState.isLoading;
        console.log('storeState', storeState);
    });
  },
  unmounted()
  {
    this.unsubscribeFromStore();
  }
}
</script>