// could be useful if going back to different filesizes
const fileServer = 'https://media.occtoo.com/743725c5-e722-43c3-a5a1-48e3ec5528ef/8521e5ac-5ac3-5fcd-a889-4599dd1e8fa5'

export function getImageUrl(fileName, optionalFolder = null) {
    return `${fileServer}/${(optionalFolder ? optionalFolder + '/' : '')}${fileName}`
}

// resourceFilename required to work
export function sortBasedOnFilenameSuffix(resources) {
    resources.sort((a, b) => {

        const suffixA = getFilenameSuffix(a.resourceFilename)
        const suffixB = getFilenameSuffix(b.resourceFilename)

        if (suffixA === null) {
            return 1
        }
        if (suffixB === null) {
            return -1
        }

        if (suffixA > suffixB) {
            return 1
        }
        else {
            return -1
        }
    })
    return resources
}

// _f{number}.png
function getFilenameSuffix(filename) {
    if (!filename) {
        return null
    }

    const split = filename.toLowerCase().split('_f')
    if (split.length > 1) {
        return split.pop()
    }
    return null
}

// #Favorites:
// const mediaUrl = imgFolderPath + '/product_icon/';
// fileName = mediaUrl + resource.resourceFilename;

// #Product display
// const mediaUrl = imgFolderPath+'/product_medium/';
// fileName = mediaUrl + resource.resourceFilename;

// #product popup
// if (resource.resourceCumulusImageView === 'Base')
// {
//     var mediaUrlBase = imgFolderPath+'/product_medium/';
//     if (large)
//     {
//         mediaUrlBase = imgFolderPath+'/product_large/';
//     }
//     imageUrl = mediaUrlBase + resource.resourceFilename;
// }
// else if (resource.resourceCumulusImageView === 'Model')
// {
//     const mediaUrlBase = imgFolderPath+'/environment_large/';
//     imageUrl = mediaUrlBase + resource.resourceFilename;
// }
// else if (resource.resourceCumulusImageView === 'DetailPhotos')
// {
//     const mediaUrlBase = imgFolderPath+'/product_medium/';
//     imageUrl = mediaUrlBase + resource.resourceFilename;
// }
// else if (resource.resourceCumulusImageView === 'Macro')
// {
//     const mediaUrlBase = imgFolderPath+'/product_medium/';
//     imageUrl = mediaUrlBase + resource.resourceFilename;
// }

// #TechnologyIcon