// NOTE (Mølby): This is where all of the data lives
// The official docs recommed VUEX for a medium to large scale SPA... I would probably consider this one quite small?
// I feel like it would be easier and simpler to create my own data store object, keeping all the data on operations in this file. If it explodes we can quickly refactor to vuex.

// const apiKey = 'xn79mCsYcnEsawZnRqH5DMXKdgDPqzsY';
// const apiUrl = 'https://outfitinternationalgateway.azurewebsites.net/api/v1/virtualshowroom';

const apiKey = 'xn79mCsYcnEsawZnRqH5DMXKdgDPqzsY';
// const apiUrl = 'http://localhost:4000/api/v2/virtualshowroom';
const apiUrl = 'https://outfitinternationalgateway.azurewebsites.net/api/v2/virtualshowroom'

var store = 
{
    defaultCurrency: 'eur',
    defaultLanguage: 'en',
    state: {
        isLoading: false,
        loadedMeetingId: null,
        meeting: null,
        customerCurrency: '',
        customerLanguage: '',
        isUpdatingFavorites: false,
        favorites: [],
        showrooms : new Map(), // Map<id, showroom>()
        showroomList: [], // list of available showrooms
        homeSceneScrollPosition: ''
    },

    _subscribers: [],

    subscribe(callback)
    {
        this._subscribers.push(callback);
        // NOTE: Do initial callback
        callback(this.state);

        // NOTE: Return unsubscribe callback
        return ()=>{
            this._subscribers = this._subscribers.filter((existingCallback) => (existingCallback !== callback));
        }
    },

    isMeetingLoaded(meetingId)
    {
        return (this.state.loadedMeetingId === parseInt(meetingId));
    },

    // load meeting and thereby also first showroom
    loadMeeting(meetingId)
    {
        meetingId = parseInt(meetingId);
        const shouldFetchMeeting = (meetingId !== -1);

        this.state.isLoading = true;
        this._updateSubscribers();

        // Clear all state data
        this.state.loadedMeetingId = null;
        this.state.meeting = null;
        this.state.favorites = [];
        this.state.showrooms = new Map();
        this.state.showroomList = [];
        this.state.homeSceneScrollPosition = '';
        this.state.customerCurrency = '';
        this.state.customerLanguage = '';

        var fetchMeetingPromise;

        // NOTE: Don't fetch meeting if meetingId is -1. Used for getting data outside of meeting context.
        if (shouldFetchMeeting)
        {
            fetchMeetingPromise = this._fetchMeeting(meetingId)
            .then((data) =>
            {
                this.state.meeting = data;
                this.state.customerCurrency = this.state.meeting.customer.customerCurrency || this.defaultCurrency;
                this.state.customerLanguage = this.state.meeting.customer.customerLanguage || this.defaultLanguage;
                this.state.showrooms.set(data.showroom.showroomID, data.showroom) //.push(); // meeting showroom
            });
        }
        else
        {
            fetchMeetingPromise = this._delayPromise(0);
        }

        return Promise.all([fetchMeetingPromise])
        .then(() =>
        {

            var fetchShowroomsPromise = this._fetchShowroomList()
            .then((data) =>
            {
                data.forEach((showroom) => {
                    this.state.showroomList.push(showroom);
                });
            });

            return Promise.all([
                fetchShowroomsPromise,
            ])
            .then(() => 
            {
                this.state.loadedMeetingId = meetingId;
                this.state.isLoading = false;
                this._updateSubscribers();
            })
            .catch((error)=>
            {
                console.error('fetch error', error);
                this.state.isLoading = false;
                this._updateSubscribers();
            });
        });
    },

    getShowroomById (id)
    {
        id = parseInt(id)
        if (this.state.showrooms.has(id))
        {
            return new Promise((resolve) => {
                resolve(this.state.showrooms.get(id))
            })
        }

        // return this._fetchShowroom(id, this.defaultLanguage, this.defaultCurrency)
        return this._fetchShowroom(id, this.state.customerLanguage, this.state.customerCurrency)
        .then((showroom) => {
            this.state.showrooms.set(showroom.showroomID, showroom)
            return showroom
        })
        .catch((err) => console.log(err))
    },

    loadFavorites(meetingId)
    {
        if (meetingId === -1 || this.state.isUpdatingFavorites) return;

        this.state.isUpdatingFavorites = true;
        this._updateSubscribers();

        return fetch(apiUrl+'/meetings/'+meetingId+'/favorites', {
            method: 'GET',
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) =>
        {
            return response.json();
        })
        .then((data)=>{
            this.state.isUpdatingFavorites = false;
            this.state.favorites = data;
            this._updateSubscribers();
        })
        .catch((error)=> {
            console.error('fetch error', error);
            this.state.isUpdatingFavorites = false;
            this._updateSubscribers();
        })
    },

    createAndSaveFavorite(meetingId, product)
    {
        if (meetingId === -1 || this.state.isUpdatingFavorites) return;

        this.state.isUpdatingFavorites = true;
        this._updateSubscribers();

        // NOTE: Create clone of object to avoid mutating original, while filtering out section and sectionIndex
        var productClone = JSON.parse(JSON.stringify(product, (key, value)=>{
            if (key === 'section') {return undefined}
            else if (key === 'sectionIndex') {return undefined}
            return value
        }));

        // console.log('ProductJson', JSON.stringify(productClone)) // TODO: DELETE LATER.
        return fetch(apiUrl+'/meetings/'+meetingId+'/favorites', {
            method: 'POST',
            body: JSON.stringify(productClone),
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) =>
        {
            return response.json();
        })
        .then((data)=>{
            this.state.isUpdatingFavorites = false;
            if (data.status === undefined)
            {
                this.state.favorites = data;
            }
            this._updateSubscribers();
        })
        .catch((error)=> {
            console.error('fetch error', error);
            this.state.isUpdatingFavorites = false;
            this._updateSubscribers();
        })
    },

    deleteFavorite(meetingId, productId)
    {
        if (meetingId === -1 || this.state.isUpdatingFavorites) return;

        this.state.isUpdatingFavorites = true;
        this._updateSubscribers();

        return fetch(apiUrl+'/meetings/'+meetingId+'/favorites/'+productId, {
            method: 'DELETE',
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) =>
        {
            return response.json();
        })
        .then((data)=>{
            this.state.isUpdatingFavorites = false;
            if (data.status === undefined)
            {
                this.state.favorites = data;
            }
            this._updateSubscribers();
        })
        .catch((error)=> {
            console.error('fetch error', error);
            this.state.isUpdatingFavorites = false;
            this._updateSubscribers();
        })
    },

    createAndSaveOrder(meetingId)
    {
        this.state.isUpdatingFavorites = true;
        this._updateSubscribers();

        return fetch(apiUrl+'/meetings/'+meetingId+'/orders', {
            method: 'POST',
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) =>
        {
            return response.json();
        })
        .then((data)=>{
            this.state.isUpdatingFavorites = false;
            this._updateSubscribers();
            return data;
        })
        .catch((error)=> {
            console.error('fetch error', error);
            this.state.isUpdatingFavorites = false;
            this._updateSubscribers();
        })
    },

    clearFavorites()
    {
        this.state.favorites = [];
        this._updateSubscribers();
    },

    deleteAllFavorites(meetingId)
    {
        if (meetingId === -1 || this.state.isUpdatingFavorites) return;

        this.state.isUpdatingFavorites = true;
        this._updateSubscribers();

        return fetch(apiUrl+'/meetings/'+meetingId+'/favorites', {
            method: 'DELETE',
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) =>
        {
            return response.json();
        })
        .then((data)=>{
            this.state.isUpdatingFavorites = false;
            if (data.status === undefined)
            {
                this.state.favorites = data;
            }
            this._updateSubscribers();
        })
        .catch((error)=> {
            console.error('fetch error', error);
            this.state.isUpdatingFavorites = false;
            this._updateSubscribers();
        })
    },

    setHomeSceneScrollPosition(scrollPosition)
    {
        this.state.homeSceneScrollPosition = scrollPosition;
        this._updateSubscribers();
    },
    
    // NOTE: Internal methods

    _updateSubscribers()
    {
        this._subscribers.forEach((callback)=>{
            callback(this.state);
        });
    },

    _fetchMeetings(customerId)
    {
        if (typeof customerId !== 'number')
        { 
            console.error(`customerId is not a number: ${ customerId }`)
            return []
        }

        // NOTE: Get list of meetings
        return fetch(apiUrl+'/meetings?customer='+customerId, {
            method: 'GET',
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) =>
        {
            return response.json();
        })
    },

    _fetchMeeting(meetingId)
    {
        // NOTE: Get meeting with all data
        return fetch(apiUrl+'/meetings/'+meetingId, {
            method: 'GET',
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) =>
        {
            return response.json();
        })
    },

    _fetchShowroomList () {
        return fetch(apiUrl+'/showrooms', {
            method: 'GET',
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) =>
        {
            return response.json();
        })
    },

    _fetchShowroom(showroomId, language, currency)
    {
        return fetch(apiUrl+'/showrooms/' + showroomId + '?language='+language+'&currency='+currency, {
            method: 'GET',
            headers: {
                'ApiKey': apiKey,
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            return response.json();
        })
    },

    _delayPromise(durationMs)
    {
        return new Promise((resolve/*, reject*/)=>{
            setTimeout(() => {
                resolve();
            }, durationMs);
        });
    }
}

export default store;