<template>

		<ShowroomScene 
			v-if="showroom !== null"
			:showroom="showroom"
			:selectedProductId="parseInt(selectedProductId)"
			:favorites="favorites"
			:isUpdatingFavorites="isUpdatingFavorites"
			:disableFavorites="(meetingId === -1)"
			:showFavoritesPostSubmitFeedback="showFavoritesPostSubmitFeedback"
			:currency="currency"
			@open-product="openProduct"
			@close-product="closeProduct"
			@delete-favorite="deleteFavorite"
			@add-favorite="addFavorite"
			@submit-favorites="submitFavorites">
		</ShowroomScene>

</template>

<script>
import store from '../dataStore';
import {setLanguage} from '@/helpers/language'
import ShowroomScene from '../components/ShowroomScene';

export default {
	name: 'Showroom',
	components: {
		ShowroomScene: ShowroomScene
	},
	data() {
		return {
			isLoading: false,
			favorites: [],
			isUpdatingFavorites: false,
			meetingId: (this.$route.params.meetingId ? this.$route.params.meetingId : -1),
			selectedProductId: null,
			showFavoritesPostSubmitFeedback: false,
			showroom: null,
			currency: ''
		}
	},
	methods:
	{
		closeProduct()
        {
			if (this.selectedProductId)
			{this.$router.push({path: '../../', query: this.$route.query});
			}
        },
		openProduct(productId)
		{
			if (this.$route.params.productId)
			{
				this.$router.push({path: '../'+productId+'/', query: this.$route.query});
			}
			else
			{
				this.$router.push({path: './product/'+productId+'/', query: this.$route.query});
			}
		},
		deleteFavorite(productId)
		{
			store.deleteFavorite(this.meetingId, productId);
		},
		addFavorite(product)
		{
			store.createAndSaveFavorite(this.meetingId, product);
		},
		submitFavorites()
		{
			store.createAndSaveOrder(this.meetingId)
				.then((result)=>{
					if (result.status === undefined) // NOTE: Success
					{
						// NOTE: Clear data locally...
						store.clearFavorites();
						this.showFavoritesPostSubmitFeedback = true;
					}
					else
					{
						alert('Error: ' + result.message);
					}
				});
		},
		loadShowroom () {
			// load showroom
			const showroomId = parseInt(this.$route.params.showroomId);
			store.getShowroomById(showroomId)
			.then((room) => { 
				this.showroom = room 
				store.state.isLoading = false
			})
		}
	},
	watch: {
		$route(to) {
			var productId = parseInt(to.params.productId);
			if (productId)
			{
				this.selectedProductId = productId;
			}
			else
			{
				this.selectedProductId = null;
			}
		}
	},
	created()
	{
		// NOTE: Load meeting if it is not already loaded
		var productId = this.$route.params.productId;

		if (productId)
		{
			this.selectedProductId = productId;
		}

		// meeting not loaded
		if (!store.isMeetingLoaded(this.meetingId))
		{
			store.loadMeeting(this.meetingId)
			.then(() => {
				store.loadFavorites(this.meetingId)
				this.loadShowroom()
			});
		}
		else
		{
			this.loadShowroom()
		}

		this.unsubscribeFromStore = store.subscribe((storeState) => {
			this.isLoading = storeState.isLoading;
			this.isUpdatingFavorites = storeState.isUpdatingFavorites;
			this.currency = storeState.defaultCurrency;
			if (storeState.meeting)
			{
				setLanguage(storeState.meeting.customer.customerLanguage);
				this.currency = storeState.meeting.customer.customerCurrency;
			}
			this.favorites = storeState.favorites;
		});
	},
	unmounted()
	{
		this.unsubscribeFromStore();
	}
}
</script>
