<template>
    <div class="favorites" :class="[{'favorites--is-updating': isUpdatingFavorites}, 'favorites--'+brandNameForCSSClass]">
        <div class="favorites__header">
            <div>{{language('favorites.heading')}}</div>
        </div>
        <div class="favorites__list" :class="{'favorites__list--has-more-than-five': favorites.length >= 5}">

            <ScrollBar>

                <div v-if="!favorites.length" class="favorites__item">
                    <div class="favorites__product-name">
                        <template v-if="showPostSubmitFeedback">
                            <span>{{language('favorites.submitFeedback')}}</span>
                        </template>
                        <template v-else>
                            <span>{{language('favorites.emptyList')}}</span>
                        </template>
                    </div>
                </div>

                <div v-for="product in favorites" v-bind:key="product.productID" class="favorites__item">
                    <div class="favorites__product-icon" @click="$emit('open-favorite', product.productID)">
                        <img :src="getProductImage(product)"/>
                    </div>
                    <div class="favorites__product-name" @click="$emit('open-favorite', product.productID)">{{product.productName}}</div>
                    <div class="favorites__product-delete-btn" @click="$emit('delete-favorite', product.productID)"></div>
                </div>

            </ScrollBar>

        </div>

        <template v-if="isDemoMode">
            <div v-if="favorites.length" class="favorites__cta-btn favorites__cta-btn--submit">No orders in demo</div>
        </template>

        <template v-else>
            <div v-if="favorites.length" class="favorites__cta-btn favorites__cta-btn--submit" @click="$emit('submit-favorites')">{{language('favorites.submitOrder')}}</div>
        </template>

        <a v-if="brandNameForCSSClass === 'harkila'" class="favorites__cta-btn" target="_blank" :href="content('showroomMenu.campaignMaterialLinkHarkila')">{{language('favorites.marketingMaterialBtn')}}</a>
        
        <a v-if="brandNameForCSSClass === 'seeland'" class="favorites__cta-btn" target="_blank" :href="content('showroomMenu.campaignMaterialLinkSeeland')">{{language('favorites.marketingMaterialBtn')}}</a>

    </div>
</template>

<script>

import ScrollBar from '@/components/ScrollBar';
import { language, content } from '@/helpers/language'
// import { getImageUrl } from '@/helpers/image-helper'

export default {
    name: 'FavoritesList',
    components: {
        ScrollBar: ScrollBar
    },
    props: {
        brandName: String,
        favorites: Array,
        isUpdatingFavorites: Boolean,
        showPostSubmitFeedback: Boolean
    },
    data()
    {
        return {
            language: language,
            content: content
        }
    },
    computed:
    {
        isDemoMode()
        {
            return (this.$route.query && this.$route.query.demomode);
        },
        brandNameForCSSClass()
        {
            if (this.brandName === 'Härkila')
            {
                return 'harkila';
            }
            else if (this.brandName === 'Seeland')
            {
                return 'seeland'
            }
            
            return '';
        }
    },
    methods:
    {
        getProductImage(product)
        {
            // Will get product image if it has resources and Base and resourceUrl
            // else brandLogo based on brandName
            if (product.resources)
            {
                const resource = product.resources.find((resource) => {
                    if (resource.resourceCumulusImageView === 'Base' && resource.resourceUrl)
                    {
                        return resource
                    }
                })
                if (resource && resource.resourceUrl)
                {
                    return resource.resourceUrl
                }
            }

            return this.getBrandLogo(product.productBrand)
        },
        getBrandLogo (brandName) {
            
            if (!brandName) { return }

            if (brandName === 'Härkila')
            {
                return '/images/harkila-logo.png'
            }
            else if (brandName === 'Seeland')
            {
                return '/images/seeland-logo.png'
            }
            else
            {
                return ''
            }
        }
    }
}
</script>