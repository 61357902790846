<template>
    <div class="scene showroom-scene">

        <div class="showroom-logo">
            <router-link :to="{path: '../../', query: $route.query}">
                <img v-if="'harkila' === brandNameForCSSClass" src="/images/harkila-logo.png"/>
                <img v-if="'seeland' === brandNameForCSSClass" src="/images/seeland-logo.png"/>
            </router-link>
        </div>

        <div class="showroom-scene__slides" :class="{'showroom-scene__slides--is-transition': isAnimating}" :style="'transform: translateX(-'+currentSlideIndex*100+'%)'">

            <div v-for="(slide, i) in slides" class="showroom-scene__slide" :class="'showroom-scene__slide--layout-'+slide.slideSpec.name" v-bind:key="i">
                <ProductDisplay
                    :layout-type="slide.slideSpec.name"
                    :section-name="slide.sectionName"
                    :section-description="slide.sectionDescription"
                    :brandName="showroom.showroomBrand"
                    :products="slide.products"
                    :environmentImageUrl="slide.environmentImageUrl"
                    @open-product="(productId)=>{$emit('open-product', productId)}">
                </ProductDisplay>

                <FavoritesList
                    v-if="!disableFavorites && (i === slides.length -1)"
                    :brandName="showroom.showroomBrand"
                    :favorites="favorites"
                    :isUpdatingFavorites="isUpdatingFavorites"
                    :showPostSubmitFeedback="showFavoritesPostSubmitFeedback"
                    @delete-favorite="(productId)=>{$emit('delete-favorite', productId)}"
                    @submit-favorites="$emit('submit-favorites')">
                </FavoritesList>

                <template v-if="'01' === slide.slideSpec.name">
                    <video 
                        class="showroom-scene__slide-video"
                        src="/videos/showroom-scene/01.m4v?v=4"
                        muted
                        autoplay
                        loop
                        playsinline
                        webkit-playsinline
                        type="video/mp4"
                        @contextmenu.prevent=""></video>
                </template>

                <template v-if="'03' === slide.slideSpec.name">
                    <video 
                        class="showroom-scene__slide-video"
                        src="/videos/showroom-scene/03.m4v?v=3"
                        muted
                        autoplay
                        loop
                        playsinline
                        webkit-playsinline
                        type="video/mp4"
                        @contextmenu.prevent=""></video>
                </template>

                <template v-if="'08' === slide.slideSpec.name">
                    <video 
                        class="showroom-scene__slide-video"
                        src="/videos/showroom-scene/08.m4v?v=2"
                        muted
                        autoplay
                        loop
                        playsinline
                        webkit-playsinline
                        type="video/mp4"
                        @contextmenu.prevent=""></video>
                </template>

                <template v-if="'09' === slide.slideSpec.name">
                    <video 
                        class="showroom-scene__slide-video"
                        src="/videos/showroom-scene/09.m4v?v=2"
                        muted
                        autoplay
                        loop
                        playsinline
                        webkit-playsinline
                        type="video/mp4"
                        @contextmenu.prevent=""></video>
                </template>

            </div>

        </div>

        <div class="showroom-scene__navigation slider-navigation" :class="'slider-navigation--'+brandNameForCSSClass" v-if="(slides.length > 1) && !selectedProduct">
            <div class="slider-navigation__items">
                <div class="slider-navigation__items-left">
                    <a v-for="(i) in prevPageIndexes" class="slider-navigation__item slider-navigation__item--dot" @click="goToPage(i)" v-bind:key="i"></a>
                    <a class="slider-navigation__item slider-navigation__item--arrow-left" :class="{'slider-navigation__item--unavailable': currentSlideIndex === 0}" @click="prevPage"></a>
                </div>
                <div class="slider-navigation__items-right">
                    <a class="slider-navigation__item slider-navigation__item--arrow-right" :class="{'slider-navigation__item--unavailable': currentSlideIndex+1 === slides.length}" @click="nextPage"></a>
                    <a v-for="(i) in nextPageIndexes" class="slider-navigation__item slider-navigation__item--dot" @click="goToPage(i)" v-bind:key="i"></a>
                </div>
            </div>
        </div>

        <ProductPopup
            v-if="selectedProduct"
            :brandName="showroom.showroomBrand"
            :product="selectedProduct"
            :siblingProducts="selectedProduct.section.products"
            :favorites="favorites"
            :isUpdatingFavorites="isUpdatingFavorites"
            :disableFavorites="disableFavorites"
            :currency="currency"
            @close-product="$emit('close-product')"
            @open-product="(productId)=>{$emit('open-product', productId)}"
            @add-favorite="(product)=>{$emit('add-favorite', product)}"
            @delete-favorite="(productId)=>{$emit('delete-favorite', productId)}">
        </ProductPopup>

        <Menu :brandName="showroom.showroomBrand" @go-to-favorites="goToFavorites"></Menu>

    </div>

</template>

<script>
import Menu from './Menu';
import ProductDisplay from './ProductDisplay';
import ProductPopup from './ProductPopup';
import FavoritesList from './FavoritesList';

export default {
    name: 'ShowroomScene',
    components: {
        Menu: Menu,
        ProductDisplay: ProductDisplay,
        ProductPopup: ProductPopup,
        FavoritesList: FavoritesList
    },
    props: {
        showroom: Object,
        selectedProductId: {
            type: Number,
            required: false
        },
        favorites: Array,
        isUpdatingFavorites: Boolean,
        showFavoritesPostSubmitFeedback: Boolean,
        disableFavorites: Boolean,
        currency: String
    },
    data()
    {
        return {
            currentSlideIndex: 0,
            transitionDurationMs: 700,
            isAnimating: false,
            animationTimeout: null,

            // NOTE: Slides
            slides: [],
            // NOTE: Slide builder variables
            slideSpecs: [
                {
                    name: '01',
                    isProductSlide: true,
                    numberOfProducts: 2,
                    hasEnvironmentImage: false
                },
                {
                    name: '02',
                    isProductSlide: true,
                    numberOfProducts: 3,
                    hasEnvironmentImage: true
                },
                {
                    name: '03',
                    isProductSlide: true,
                    numberOfProducts: 4,
                    hasEnvironmentImage: true
                },
                {
                    name: '04',
                    isProductSlide: true,
                    numberOfProducts: 4,
                    hasEnvironmentImage: true
                },
                // NOTE: Start of loop
                {
                    name: '05',
                    isProductSlide: true,
                    numberOfProducts: 6,
                    hasEnvironmentImage: true
                },
                {
                    name: '06',
                    isProductSlide: true,
                    numberOfProducts: 6,
                    hasEnvironmentImage: true
                },
                {
                    name: '07',
                    isProductSlide: true,
                    numberOfProducts: 6,
                    hasEnvironmentImage: true
                },
                // NOTE: End of loop
                {
                    name: '08',
                    isProductSlide: true,
                    numberOfProducts: 3,
                    hasEnvironmentImage: false
                },
                {
                    name: '09',
                    isProductSlide: false // NOTE: A single slide will be created from this if false.
                }
            ],
            slideSpecsLoopFirstIndex: 4,
            slideSpecsLoopLastIndex: 6,
            currentSlideSpecIndex: -1,
            currentSlide: null,
            loopSlideIndex: null,
            productSlotsPastLoop: null,
            environmentImagesUsedFromShowroomLevel: 0,
            environmentImagesUsedFromCurrentSection: 0
        }
    },
    computed: {
        selectedProduct()
        {
            var matchingProduct = null;

            this.showroom.sections.forEach((section)=>
            {
                section.products.forEach((product)=>
                {
                    if (product.productID == this.selectedProductId)
                    {
                        matchingProduct = product;
                    }
                });
            });

            return matchingProduct;
        },
        prevPageIndexes()
        {
            var result = [];

            for (var i = 0; i < this.currentSlideIndex; i++)
            {
                result.push(i);
            }

            return result;
        },
        nextPageIndexes()
        {
            var result = [];

            for (var i = this.currentSlideIndex+1; i < this.slides.length; i++)
            {
                result.push(i);
            }

            return result;
        },
        brandNameForCSSClass()
        {
            if (this.showroom.showroomBrand === 'Härkila')
            {
                return 'harkila';
            }
            else if (this.showroom.showroomBrand === 'Seeland')
            {
                return 'seeland'
            }
            
            return '';
        }
    },
    watch:
    {
        selectedProductId()
        {
            if (this.selectedProductId)
            {
                var indexOfSelectedProductPage = this.getSlideIndexOfProduct(this.selectedProductId);
                this.currentSlideIndex = indexOfSelectedProductPage;
            }
        }
    },
    created()
    {
        this.buildSlides();

        if (this.selectedProductId)
        {
            var indexOfSelectedProductPage = this.getSlideIndexOfProduct(this.selectedProductId);
            this.currentSlideIndex = indexOfSelectedProductPage;
        }

        // NOTE: Keyboard events
        this.handleKeyInput = (event)=>
        {
            if (!this.selectedProduct)
            {
                if (event.keyCode == '37')
                {
                    this.prevPage();
                }
                else if (event.keyCode == '39')
                {
                    this.nextPage();
                }
            }
        };
        document.addEventListener('keydown', this.handleKeyInput);
    },
    unmounted()
    {
        document.removeEventListener('keydown', this.handleKeyInput);
    },
    methods:
    {
        goToFavorites()
        {
            this.$emit('close-product');
            this.currentSlideIndex = this.slides.length-1;
        },
        getSlideIndexOfProduct(productId)
        {
            var result = null;

            this.slides.forEach((slide, slideIndex)=>{
                
                slide.products.forEach((product)=>{
                    if (product.productID == productId)
                    {
                        result = slideIndex;
                    }
                });

            });

            return result;
        },
        startTransition()
        {
            this.isAnimating = true;

            if (this.animationTimeout)
            {
                clearTimeout(this.animationTimeout);
            }

            this.animationTimeout = setTimeout(()=>{
                this.isAnimating = false;
                this.$forceUpdate();
                this.animationTimeout = null;
            }, this.transitionDurationMs);
        },
        goToPage(index)
        {
            this.currentSlideIndex = index;
            this.startTransition();
        },
        prevPage()
        {
            if (this.currentSlideIndex > 0)
            {
                this.currentSlideIndex--;
                this.startTransition();
            }
        },
        nextPage()
        {
            if (this.currentSlideIndex+1 < this.slides.length)
            {
                this.currentSlideIndex++;
                this.startTransition();
            }
        },
        buildSlides()
        {
            // NOTE: Setup shared numbers...
            this.loopSlideIndex = this.slideSpecsLoopFirstIndex;
            this.productSlotsPastLoop = this.countProductSlotsPastLoop();

            // NOTE: For each section
            this.showroom.sections.forEach((section, sectionIndex)=>
            {
                // NOTE: Reset environment image counter
                this.environmentImagesUsedFromCurrentSection = 0;

                // NOTE: Create first slide for new section
                this.addSlide(section, sectionIndex, section.products.length);

                // NOTE: For each product in section
                section.products.forEach((product, i)=>
                {                    
                    // NOTE: Add handy variables to product...
                    product.sectionIndex = i;
                    product.section = section;

                    // NOTE: Add product to current slide
                    this.currentSlide.products.push(product);

                    // NOTE: Check if we are out of product slots in this slide
                    var remainingProductsToPlace = (section.products.length - (i+1));
                    var remainingProductSlotsInSlide = (this.slideSpecs[this.currentSlideSpecIndex].numberOfProducts - this.currentSlide.products.length);
                    if (remainingProductsToPlace && !remainingProductSlotsInSlide)
                    {
                        // NOTE: There are more products in this section, so we need another slide inside of this section.
                        this.addSlide(section, sectionIndex, remainingProductsToPlace);
                    }
                });
            });

            this.addFinalNonProductSlides();
        },
        addSlide(section, sectionIndex, remainingProductsToPlace)
        {
            // NOTE: Figure out which slidespec to use next

            // NOTE: Find out if we should show slides past the loop.
            var isLastSection = (sectionIndex + 1 === this.showroom.sections.length);
            var isPastLoop = (isLastSection && (remainingProductsToPlace <= this.productSlotsPastLoop));


            // NOTE: If we are past the loop and set current slide to last spec in loop... so we will increment past it...
            if (isPastLoop && this.currentSlideSpecIndex < this.slideSpecsLoopLastIndex)
            {
                this.currentSlideSpecIndex = this.slideSpecsLoopLastIndex;
            }

            // NOTE: Find out if looping:
            var isLooping = (!isPastLoop && this.slides.length > this.slideSpecsLoopFirstIndex);
            
            if (isLooping)
            {
                if (this.loopSlideIndex + 1 > this.slideSpecsLoopLastIndex)
                {
                    this.loopSlideIndex = this.slideSpecsLoopFirstIndex;
                }
                else
                {
                    this.loopSlideIndex++;
                }
                this.currentSlideSpecIndex = this.loopSlideIndex;
            }
            else if (this.currentSlideSpecIndex < this.slideSpecs.length && this.currentSlideSpecIndex + 1 < this.slideSpecs.length)
            {
                this.currentSlideSpecIndex++;
            }

            // NOTE: Get environment image for slide
            var environmentImageUrl = null;
            if (this.slideSpecs[this.currentSlideSpecIndex].hasEnvironmentImage)
            {
                var selectedResource = null;

                // NOTE: Look for environment image in section
                section.resources.forEach((resource, i)=>{
                    if (this.environmentImagesUsedFromCurrentSection <= i && !selectedResource)
                    {
                        selectedResource = resource;
                        this.environmentImagesUsedFromCurrentSection++;

                        // NOTE: Reset counter to wrap start displaying environment images from the beginning
                        if (this.environmentImagesUsedFromCurrentSection >= section.resources.length)
                        {
                            this.environmentImagesUsedFromCurrentSection = 0;
                        }
                    }
                });

                if (selectedResource)
                {
                    environmentImageUrl = selectedResource.resourceUrl
                }
            }

            this.slides.push({
                sectionName: section.sectionName,
                sectionDescription: section.sectionDescription,
                slideSpec: this.slideSpecs[this.currentSlideSpecIndex],
                products: [],
                environmentImageUrl: environmentImageUrl
            });
            this.currentSlide = this.slides[this.slides.length-1];
        },
        addFinalNonProductSlides()
        {
            this.slideSpecs.forEach((slideSpec)=>
            {
                if (!slideSpec.isProductSlide)
                {
                    this.slides.push({
                        sectionName: '',
                        sectionDescription: '',
                        slideSpec: slideSpec,
                        products: [],
                    });
                    this.currentSlide = this.slides[this.slides.length-1];
                }
            });
        },
        countProductSlotsPastLoop()
        {
            var result = 0;
            this.slideSpecs.forEach((slideSpec, i)=>
            {
                if (slideSpec.isProductSlide && i > this.slideSpecsLoopLastIndex)
                {
                    result += slideSpec.numberOfProducts;
                }
            });
            return result;
        }
    }
}
</script>
