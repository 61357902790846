<template>
	<HomeScene
		v-bind:key="this.$route.params.meetingId"
		:isLoading="isLoading"
		:meeting="meeting"
		:meetings="meetings"
		:showroomList="showroomList"
		:initialScrollPosition="initialScrollPosition"
		@destroyed="retainFinalScrollPosition" />
</template>

<script>
import store from '../dataStore';
import {setLanguage} from '@/helpers/language'
import HomeScene from '../components/HomeScene';

export default {
	name: 'Home',
	components: {
		HomeScene: HomeScene
	},
	data() {
		return {
			isLoading: false,
			meeting: null,
			meetings: null,
			showroomList: null,
			initialScrollPosition: (store.state.homeSceneScrollPosition) ? store.state.homeSceneScrollPosition : 'center'
		}
	},
	methods:
	{
		retainFinalScrollPosition(finalScrollPosition)
		{
			store.setHomeSceneScrollPosition(finalScrollPosition);
		}
	},
	watch: {
		$route(to) {
			var meetingId = (to.params.meetingId ? to.params.meetingId : -1);

			if (!store.isMeetingLoaded(meetingId))
			{
				this.initialScrollPosition = 'center';
				store.loadMeeting(meetingId)
					.then(()=>{
						store.loadFavorites(meetingId);
					});
			}
		}
	},
	created()
	{
		// NOTE: Load customer if it is not already loaded
		// var customerId = this.$route.params.customerId;
		var meetingId = (this.$route.params.meetingId ? this.$route.params.meetingId : -1);

		if (!store.isMeetingLoaded(meetingId))
		{
			this.initialScrollPosition = 'center';
			store.loadMeeting(meetingId)
				.then(()=>{
					store.loadFavorites(meetingId);
				});
		}

		this.unsubscribeFromStore = store.subscribe((storeState)=>{
			this.isLoading = storeState.isLoading;
			// this.customer = storeState.customer;
			this.meeting = storeState.meeting;
			this.meetings = storeState.meetings;
			this.showroomsHarkila = storeState.showroomsHarkila;
			this.showroomsSeeland = storeState.showroomsSeeland;
			this.showroomList = storeState.showroomList;
			if (storeState.meeting)
			{
				setLanguage(storeState.meeting.customer.customerLanguage);
			}
		});
	},
	unmounted()
	{
		this.unsubscribeFromStore();
	}
}
</script>
