<template>
    <div class="product-display" :class="['product-display--'+layoutType, 'product-display--'+brandNameForCSSClass]">

        <div class="product-display__title">
            <!--<div class="product-display__title-name">{{sectionName}}</div>
            <div class="product-display__title-description">{{sectionDescription}}</div>-->
            <div class="product-display__title-description">{{sectionName}}</div>
        </div>

        <!-- removed environment images, just outcommnet for now -->
        <!-- <div v-if="environmentImageUrl" class="product-display__environment-image">
            <img :src="environmentImageUrl"/>
        </div> -->

        <div class="product-display__products">

            <template v-for="product in products" v-bind:key="product.productID">
                <div class="product-frame" :class="['product-frame--'+layoutType, 'product-frame--'+brandNameForCSSClass]" @click="openProduct(product.productID)">
                    <div class="product-frame__title-bar">
                        <div class="product-frame__name">
                            <div class="product-frame__name-text">{{product.productName}}</div>
                        </div>
                        <div class="product-frame__number">{{getProductNumber(product.sectionIndex)}}</div>
                    </div>
                    <div class="product-display__image-container">
                        <img :src="getProductImage(product)"/>
                    </div>
                </div>
            </template>

        </div>

    </div>
</template>

<script>
import pad from '@/helpers/pad';
import { sortBasedOnFilenameSuffix } from '@/helpers/image-helper';

export default {
    name: 'ProductDisplay',
    props: {
        layoutType: String,
        sectionName: String,
        sectionDescription: String,
        brandName: String,
        products: Array,
        environmentImageUrl: String
    },
    data()
    {
        return {}
    },
    computed: {
        brandNameForCSSClass()
        {
            if (this.brandName === 'Härkila')
            {
                return 'harkila';
            }
            else if (this.brandName === 'Seeland')
            {
                return 'seeland'
            }
            
            return '';
        }
    },
    methods:
    {
        getProductImage(product)
        {
            if (!product.resources || !Array.isArray(product.resources)) return

            // clone and return sorted based on filename "_f01.png"
            const sortedResources = sortBasedOnFilenameSuffix([...product.resources.filter(res => res.resourceCumulusImageView === 'Base')])

            if (sortedResources.length > 0)
            {
                return sortedResources[0].resourceUrl
            }
            else
            {
                return
            }
        },
        getProductNumber(number)
        {
            return pad(number + 1, 2);
        },
        openProduct(productId)
        {
            this.$emit('openProduct', productId);
        }
    }
}
</script>
