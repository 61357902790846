import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Showroom from '../views/Showroom.vue';
import Page404 from '../views/Page404.vue';

const routes = [
  /*
  {
    path: '/',
    name: 'No meeting',
    component: Home
  },
  {
    path: '/showroom/:showroomId/',
    name: 'Showroom without meeting',
    component: Showroom
  },
  {
    path: '/showroom/:showroomId/product/:productId/',
    name: 'Product without meeting',
    component: Showroom
  },
  */
  {
    path: '/meeting/:meetingId/',
    name: 'Home',
    component: Home
  },
  {
    path: '/meeting/:meetingId/showroom/:showroomId/',
    name: 'Showroom',
    component: Showroom
  },
  {
    path: '/meeting/:meetingId/showroom/:showroomId/product/:productId/',
    name: 'Product',
    component: Showroom
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Page404
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  strict: true
});

export default router
