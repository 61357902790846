<template>
    <div class="leave-meeting-warning">
        <div class="leave-meeting-warning__overlay"></div>
        <div class="leave-meeting-warning__window">
            <div class="leave-meeting-warning__header">
                <div>{{language('leaveMeeting.warning')}}</div>
            </div>
            <div class="leave-meeting-warning__body">
                <div class="leave-meeting-warning__close-btn" @click="$emit('close')"></div>
                <div class="leave-meeting-warning__title">{{language('leaveMeeting.heading')}}</div>
                <div class="leave-meeting-warning__text">
                    <p>{{language('leaveMeeting.text1')}}</p>
                    <p>{{language('leaveMeeting.text2')}}</p>
                </div>
                <router-link class="leave-meeting-warning__link" :to="linkToMeeting(meeting.meetingID, $route)">OK</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {language} from '@/helpers/language'
import {linkToMeeting} from '@/helpers/linkHelper';

export default {
    name: 'LeaveMeetingWarning',
    components: {},
    props: {
        meeting: Object
    },
    data()
    {
        return {
            language: language,
            linkToMeeting: linkToMeeting
        }
    }
}
</script>
