<template>
    <div>
    <div class="menu" :class="[{'menu--is-open': isOpen}, 'menu--'+brandNameForCSSClass]">
        <button class="menu__toggle-btn" @click="isOpen = !isOpen">
            <span class="menu__btn-label" v-if="!isOpen">{{language('showroomMenu.menu')}}</span>
            <span class="menu__btn-label" v-else>{{language('showroomMenu.close')}}</span>
        </button>
        <nav class="menu__panel">
            <div class="menu__list showroom-links" :class="'showroom-links--'+brandNameForCSSClass">
                <div class="showroom-links__heading">{{language('showroomMenu.navigation')}}</div>
                <div><router-link :to="{path: '../../', query: $route.query}" class="showroom-links__link">{{language('showroomMenu.chooseShowroom')}}</router-link></div>
                <div><a class="showroom-links__link" target="_blank" :href="content('showroomMenu.b2bPortalLink')">{{language('showroomMenu.b2bPortal')}}</a></div>
            </div>
            <div class="menu__list showroom-links" :class="'showroom-links--'+brandNameForCSSClass">
                <div class="showroom-links__heading">{{language('showroomMenu.marketingMaterial')}}</div>
                <template v-if="brandNameForCSSClass === 'harkila'">
                    <div><a class="showroom-links__link" :href="content('showroomMenu.capPlanLinkHarkila')" target="_blank">{{language('showroomMenu.capPlan')}}</a></div>
                    <div><a class="showroom-links__link" :href="content('showroomMenu.campaignMaterialLinkHarkila')" target="_blank">{{language('showroomMenu.campaignMaterial')}}</a></div>
                    <div><a class="showroom-links__link" @click="brandVideoIsOpen = true">{{language('showroomMenu.brandVideo')}}</a></div>
                </template>

                <template v-if="brandNameForCSSClass === 'seeland'">
                    <div><a class="showroom-links__link" :href="content('showroomMenu.capPlanLinkSeeland')" target="_blank">{{language('showroomMenu.capPlan')}}</a></div>
                    <div><a class="showroom-links__link" :href="content('showroomMenu.campaignMaterialLinkSeeland')" target="_blank">{{language('showroomMenu.campaignMaterial')}}</a></div>
                    <div><a class="showroom-links__link" @click="brandVideoIsOpen = true">{{language('showroomMenu.brandVideo')}}</a></div>
                </template>
            </div>
            <div class="menu__list showroom-links" :class="'showroom-links--'+brandNameForCSSClass">
                <div class="showroom-links__heading"></div>
                <div><a class="showroom-links__link showroom-links__link--favorites" @click="goToFavorites">{{language('showroomMenu.favorites')}}</a></div>
            </div>
        </nav>
    </div>
    <div v-if="brandVideoIsOpen" class="video-popup" :class="'video-popup--'+brandNameForCSSClass">
        <div class="video-popup__overlay" @click="brandVideoIsOpen = false"></div>
        <div class="video-popup__inner">
            <div class="video-popup__close-btn" @click="brandVideoIsOpen = false"></div>
            <div class="video-popup__header">{{language('showroomMenu.brandVideo')}}</div>
            <div class="video-popup__frame">
                <video
                    v-if="brandNameForCSSClass === 'harkila'"
                    :src="content('showroomMenu.brandVideoSrcHarkila')"
                    width="1920"
                    height="1080"
                    autoplay
                    controls>
                </video>
                <video
                    v-if="brandNameForCSSClass === 'seeland'"
                    :src="content('showroomMenu.brandVideoSrcSeeland')"
                    width="1920"
                    height="1080"
                    autoplay
                    controls>
                </video>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import {language, content} from '@/helpers/language'

export default {
    name: 'Menu',
    props: {
        brandName: String,
    },
    data()
    {
        return {
            isOpen: false,
            brandVideoIsOpen: false,
            language: language,
            content: content
        }
    },
    computed: {
        brandNameForCSSClass()
        {
            if (this.brandName === 'Härkila')
            {
                return 'harkila';
            }
            else if (this.brandName === 'Seeland')
            {
                return 'seeland'
            }
            
            return '';
        }
    },
    methods:
    {
        goToFavorites()
        {
            this.isOpen = false;
            this.$emit('go-to-favorites');
        }
    }
}
</script>
