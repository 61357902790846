<template>
    <div class="scene home-scene" :class="'home-scene--'+scrollPosition">
        <div class="home-scene__frame">

            <video 
                src="/videos/home-scene/outfit-home.m4v?v=2"
                class="home-scene__video"
                muted
                autoplay
                loop
                playsinline
                webkit-playsinline
                type="video/mp4"
                @contextmenu.prevent=""></video>
        
            <template v-if="!isLoading">
                <!-- Left Seeland showroom links-->
                <div class="home-scene__your-showrooms-seeland showroom-links showroom-links--seeland showroom-links--right-align">
                    <div class="showroom-links__heading">{{language('homepage.generalShowrooms')}}</div>
                    <div class="showroom-links__list" style="height: 300px;">
                        <ScrollBar leftSide>
                            <template v-for="showroom in seelandShowroomList" v-bind:key="showroom.showroomID">
                                <router-link class="showroom-links__link" :to="linkToShowRoom(showroom.showroomID, $route)">{{translateObject(showroom.showroomName)}}</router-link>
                            </template>
                        </ScrollBar>
                    </div>
                </div>

                <div v-if="meeting" class="home-scene__welcome" :class="[{'home-scene__welcome--visible': (scrollPosition === 'center')}, 'home-scene__welcome--'+brandNameForCSSClass]">
                    <template v-if="meeting.customer.customerName">
                        <div class="home-scene__label">{{language('homepage.welcome')}} - {{meeting.meetingDate}}</div>
                        <div class="home-scene__name">{{meeting.customer.customerName}}</div>
                    </template>
                    <template v-if="meeting.meetingTitle">
                        <div class="home-scene__label">{{language('homepage.meetingDetails')}}</div>
                        <div class="home-scene__name home-scene__name--meeting">{{meeting.meetingTitle}}</div>
                        <div>
                            <ul class="home-scene__agenda-list" v-if="meeting.meetingAgenda">
                                <li class="home-scene__agenda-item" v-for="(item, i) in meeting.meetingAgenda" v-bind:key="i">{{item}}</li>
                            </ul>
                        </div>
                    </template>

                    <router-link v-if="meeting.showroom && meeting.showroom.showroomID" class="home-scene__big-showroom-button" :to="linkToShowRoom(meeting.showroom.showroomID, $route)">{{language('homepage.showroomButtonText')}}</router-link>
                </div>

                <div class="home-scene__left-sign-link" @click="navigateToSide('left')" @mouseover="signHover = 'left'" @mouseleave="signHover = false"></div>
                <div v-if="scrollPosition === 'center'" class="home-scene__sign-arrow home-scene__sign-arrow--left" :class="{'home-scene__sign-arrow--hover': signHover === 'left'}" style="left:1329px;top:748px;"></div>
                <div v-if="scrollPosition === 'left'" class="home-scene__sign-arrow home-scene__sign-arrow--right" :class="{'home-scene__sign-arrow--hover': signHover === 'left'}" style="left:1657px;top:741px;"></div>

                <div class="home-scene__right-sign-link" @click="navigateToSide('right')" @mouseover="signHover = 'right'" @mouseleave="signHover = false"></div>
                <div v-if="scrollPosition === 'right'" class="home-scene__sign-arrow home-scene__sign-arrow--left" :class="{'home-scene__sign-arrow--hover': signHover === 'right'}" style="left:2080px;top:667px;"></div>
                <div v-if="scrollPosition === 'center'" class="home-scene__sign-arrow home-scene__sign-arrow--right" :class="{'home-scene__sign-arrow--hover': signHover === 'right'}" style="left:2412px;top:569px;"></div>

                <div class="home-scene__slogan"></div>

                <div class="home-scene__your-showrooms-harkila showroom-links showroom-links--harkila">
                    <div class="showroom-links__heading">{{language('homepage.generalShowrooms')}}</div>
                    <div class="showroom-links__list" style="height: 300px;">
                        <ScrollBar>
                            <template v-for="showroom in harkilaShowroomList" v-bind:key="showroom.showroomID">
                                <router-link class="showroom-links__link" :to="linkToShowRoom(showroom.showroomID, $route)">{{translateObject(showroom.showroomName)}}</router-link>
                            </template>
                        </ScrollBar>
                    </div>
                </div>

            </template>

        </div>

        <LeaveMeetingWarning v-if="selectedOtherMeeting" :meeting="selectedOtherMeeting" @close="selectedOtherMeeting = null"></LeaveMeetingWarning>

    </div>

</template>

<script>
import LeaveMeetingWarning from '@/components/LeaveMeetingWarning';
import ScrollBar from '@/components/ScrollBar';
import { language, translateObject } from '@/helpers/language'
import {linkToShowRoom} from '@/helpers/linkHelper';

export default {
    name: 'HomeScene',
    components: {
        LeaveMeetingWarning: LeaveMeetingWarning,
        ScrollBar: ScrollBar
    },
    props: {
        isLoading: Boolean,
        // customer: Object,
        meeting: Object,
        meetings: Array,
        showroomList: Array,
        initialScrollPosition: String,
    },
    data()
    {
        return {
            scrollPosition: this.initialScrollPosition,
            selectedMeeting: null,
            selectedOtherMeeting: null,
            signHover: false,
            language: language,
            translateObject: translateObject,
            linkToShowRoom: linkToShowRoom
        }
    },
    computed:
    {
        brandNameForCSSClass()
        {
            if (this.meeting.showroom && this.meeting.showroom.showroomBrand  === 'Härkila')
            {
                return 'harkila';
            }
            else if (this.meeting.showroom && this.meeting.showroom.showroomBrand === 'Seeland')
            {
                return 'seeland'
            }
            
            return '';
        },
        seelandShowroomList () {
            return this.showroomList.filter((room) => room.showroomBrand === 'Seeland')
        },
        harkilaShowroomList () {
            return this.showroomList.filter((room) => room.showroomBrand === 'Härkila')
        }
    },
    mounted() {},
    unmounted()
    {
        this.$emit('destroyed', this.scrollPosition);
    },
    methods:
    {
        navigateToSide(scrollPosition)
        {
            if (this.scrollPosition === scrollPosition)
            {
                this.scrollPosition = 'center';
            }
            else
            {
                this.scrollPosition = scrollPosition;
            }
        }
    }
}
</script>
